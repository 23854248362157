import { Injectable } from '@angular/core';
import {
  FormControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { INPUT_INFORMATIONS_API_CONSTANT } from '../constant/api-constant';
import { ValidatorService } from './validator.service';

@Injectable({
  providedIn: 'root',
})
export class Validator {
  constructor(private validatorService: ValidatorService) {}

  /**
   * 共通バリデーションチェック(カスタムバリデーター)
   * @param tblId テーブル名
   * @param colId カラム名
   * @param inputType 入力項目タイプ
   */
  public CheckValidator(
    tblId: string,
    colId: string,
    inputType: string
  ): AsyncValidatorFn {
    return (
      control: FormControl
    ):
      | Promise<ValidationErrors | null>
      | Observable<ValidationErrors | null> => {
      // 入力項目値格納先
      let inputValue;
      if(INPUT_INFORMATIONS_API_CONSTANT.CHECKBOX_TYPE == inputType){
        inputValue = control.value;
      }
      else if (typeof(control.value) == "object" && control.value) {
        inputValue = control.value["value"];
      } else {
        inputValue = control.value;
      }

      let colId2 = "";
      let val2 = "";
      let control2;
      if (colId == "agenda_need_event_date"){
        colId2 = "agenda_need_event_date_end"
        let formParent = control.parent;
        if (formParent){
          val2 = formParent.get(colId2)?.value;
          control2 = formParent.get(colId2);
        }
      }else if (colId == "agenda_need_event_date_end"){
        colId2 = "agenda_need_event_date"
        let formParent = control.parent;
        if (formParent){
          val2 = formParent.get(colId2)?.value;
          control2 = formParent.get(colId2);
        }
      }

      // チェックAPIを呼び出し
        return this.validatorService
        .getValidatorResult(tblId, colId, inputValue, colId2, val2)
        .pipe(
          map((response) => {
            /* 入力文字列の置換 */
            // 入力タイプを判定
            if (
              INPUT_INFORMATIONS_API_CONSTANT.TEXT_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.NUMBER_TYPE == inputType ||
              INPUT_INFORMATIONS_API_CONSTANT.TEXTAREA_TYPE == inputType
            ) {
              // 入力タイプが'テキスト'or'数値'or'テキストエリア'の場合

              // 入力文字列と置換文字列が別々か否か
              if (inputValue != response.body.value) {
                // 入力文字列と置換文字列が別々の場合

                // 文字を置き換える
                control.setValue(response.body.value);
              }
            }

            // エラーメッセージ出力先格納変数
            let errors = new Object();
            // エラーメッセージが存在するか否か
            if (response.body.message) {
              // エラーメッセージが存在する場合

              // エラーメッセージ出力先格納変数にエラーメッセージを格納
              errors['message'] = response.body.message;
            }else{
              if ((colId == "agenda_need_event_date" || colId == "agenda_need_event_date_end") && control2){
                control2.setErrors(null);
              }
            }
            return errors;
          })
        );
    } 
  }
}

import { Component, ElementRef, ViewChild, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, Location } from "@angular/common";
import {
  AGENDA_SOLUTIONS_APPROVE,
  LOGIN_NOT_AGENDA_SOLUTIONS,
  API_URL_AGENDA_SOLUTION_DELETE,
  API_URL_INPUT_MASTER,
  API_URL_AGENDA_SOLUTION_DETAIL,
  API_URL_AGENDA_SOLUTION_ADD,
} from "manager/http-constants";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import {
  LoadData,
  LoadingState,
} from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { PlatformComponent } from "../platform.component";
import { ApprovalDialogComponent } from "../common/approval-dialog/approval-dialog.component";
import {
  AGENDA_SOLUTION_M301_TEMPLATE,
} from "manager/template-constant";
import { LoginService } from "src/app/shared/service/login.service";
import { GenerateInputComponent } from "src/app/shared/generate/generate-input/generate-input.component";
import { GENERATE_INPUT_TYPE } from "src/app/shared/generate/generate-input/constant";
import { DeleteDialogComponent } from "src/app/shared/html-parts/delete-dialog/delete-dialog.component";
import { MATCH_TYPE } from "src/app/shared/constant/constant";
import { IMAGE_URL, NO_ACHIEVEMENT_ICON } from "manager/file-contstants";
import { LinkCheckService } from "src/app/shared/service/link-check.service";
@Component({
  selector: "app-c331",
  templateUrl: "./c331.component.html",
  styleUrls: ["../master/master.component.scss","./c331.component.scss", "../platform.component.scss"],
})

/**
 * 課題・ソリューション詳細
 */
export class C331Component extends PlatformComponent {
  /** 共通部品_承認時ダイアログ */
  @ViewChild(ApprovalDialogComponent)
  approvalDialogComponent: ApprovalDialogComponent;
  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  @ViewChild('avatarContainer') avatarContainer: ElementRef;

  // 課題ID
  agendaId: string = this.route.snapshot.params.agenda_id;

  // 課題・ソリューション詳細格納先
  detailTask: any;

  // マッチング依頼一覧格納先
  matchingList: any;

  // 質問掲示板の件数格納先
  countQuestionBoard: number = 0;
  class_id: any;
  // variable to show content
  isDataLoaded: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private messageData: MessageData,
    private location: Location,
    private datePipe: DatePipe,
    private loginService: LoginService,
    private linkCheckService: LinkCheckService,
    private renderer: Renderer2
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  public async ngOnInit() {
    // 辞書値リストを取得

    // ユーザマスタに存在する場合
    this.class_id = this.loginUser.class_id;

    await this.getDetailTask();
  }


  /**
   * 課題・ソリューション詳細を取得
   */
  public getDetailTask() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOGIN_NOT_AGENDA_SOLUTIONS);

    return new Promise<any>((resolve) => {
      // 課題・ソリューション詳細を取得
      this.dbOperationService
        .getNoTemplateDisplayData(LOGIN_NOT_AGENDA_SOLUTIONS, this.agendaId)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, LOGIN_NOT_AGENDA_SOLUTIONS);

          if (this.commonService.checkInvalidValue(response)) {
            this.isDataLoaded = false;
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );

            // 前画面へ戻る
            this.location.back();
          } else {
            this.isDataLoaded = true;
            // 課題・ソリューション詳細を格納
            this.detailTask = response.body;
            this.setAvatar(this.detailTask);

            // 公開掲示板をデフォルトで表示
            // if (1 == this.detailTask?.board_open.id && this.decisionLoginState()) {
            //   this.moveChat()
            // }
          }
          resolve(null);
        });
    });
  }

  /**
   * 課題・ソリューション登録(C101)へ遷移する
   */
  public moveC101(): void {
    // 課題・ソリューション登録(C101)へ遷移
    this.router.navigate(["pages/platform/c101/edit"], {
      queryParams: {
        screenId: "c101",
        agenda_id: this.route.snapshot.params.agenda_id,
        agenda_kbn: this.detailTask.agenda_kbn.id,
      },
    });
  }

  /**
   * マッチング提案掲示板(C601)へ遷移する
   * @param suggestId 提案ID
   */
  public moveC601(suggestId: string): void {
    // マッチング提案掲示板(C601)へ遷移
    this.router.navigate(["pages/platform/c601/" + suggestId], {
      queryParams: {
        agenda_id: this.route.snapshot.params.agenda_id,
      },
    });
  }

  public async moveM301() {
    const pkeyId = this.route.snapshot.params.agenda_id;
    // 編集画面表示
    await this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_MASTER,
      API_URL_AGENDA_SOLUTION_DETAIL,
      API_URL_AGENDA_SOLUTION_ADD,
      AGENDA_SOLUTION_M301_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 内閣府承認ダイアログ表示
   */
  public openSmallApprovalDialog(): void {
    // 内閣府承認ダイアログ表示
    this.approvalDialogComponent.openSmallApprovalDialog(
      AGENDA_SOLUTIONS_APPROVE,
      this.agendaId,
      2003,
      0,
      1
    );
  }



  /**
   * 課題・ソリューション検索(C201)へ遷移する
   *
   */
  public moveC201(): void {
    //課題・ソリューション検索(C201)へ遷移する
    this.router.navigate(["pages/platform/c201"]);
  }

  moveC231(){
    history.back();
  }
  public movec401(): void {
    this.router.navigate(["pages/platform/c401/new"], {
      queryParams: {
        screenId: "c401",
        agenda_id: this.route.snapshot.params.agenda_id,
        match_type: MATCH_TYPE.AGENDA_SOLUTION,
      },
    });
  }

  public async deleteAgendaSoltionById() {
    const id = this.route.snapshot.params.agenda_id;
    const title = '支援'
    // 削除ダイアログにより削除処理
    await this.deleteDialogComponent.deleteDialog(
      API_URL_AGENDA_SOLUTION_DELETE,
      "支援ID",
      id,
      title
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    // 検索処理を実施
    this.getDetailTask();

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadSleepEnd(0.3);
  }

  redirectToList(event) {
    this.moveC231();
  }

  setAvatar(detailTask: any) {
    this.linkCheckService.validImage(IMAGE_URL.replace("{%s}", detailTask?.integration_id)).subscribe(imgUrl => {
      this.renderer.setStyle(this.avatarContainer.nativeElement, 'backgroundImage', `url(${imgUrl})`);
    });
  }

  /**
   * Opens the guide document in a new window
   * Opens a PDF file hosted on AWS S3 in a new browser tab and gives it focus
   */
  openGuideDocument() {
    window.open("https://tokyo-ss-prod-file.s3.ap-northeast-1.amazonaws.com/common/%E3%82%B5%E3%83%9D%E3%83%BC%E3%82%BF%E3%83%BC%E5%AE%9F%E7%B8%BE%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6(%E4%B8%BB%E5%82%AC%E8%80%85%E5%90%91%E3%81%91).pdf", '_blank').focus();
  }

  /**
   * Handles errors when loading ac1hievement icons by setting a default fallback icon
   * @param event The error event from the image load failure
   */
  handleAchievementIconError(event: any){
    event.target.src = NO_ACHIEVEMENT_ICON;
  }
}

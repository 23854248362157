

<!-- メイン -->
<div class="main">
  <div class="main-content">
    <div class="p-fluid p-formgrid p-grid search-screen">
      <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid search-screen-col grid">
          <div class="p-col-3 p-lg-3 p-md-3 p-xl-3 p-flex-sm-nowrap side-left">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12">
                <form
                  [formGroup]="c231SearchForm"
                  (ngSubmit)="getResultTaskDataList(true)"
                >
                  <p-accordion [multiple]="true">
                    <p-accordionTab
                      header="希望活動地域"
                      [disabled]="isDisabledTab('agenda_solution_activity_area')"
                      [(selected)]="accordion_agenda_solution_activity_area"
                    >
                      <div class="accrodion-form-list">
                        <div class="btn-check">
                          <span
                            ><a
                              href="javascript:void(0);"
                              (click)="
                                checkLargeCategory(
                                  'checkAll',
                                  getDicList(102),
                                  'agenda_solution_activity_area'
                                )
                              "
                              >すべて選択</a
                            > </span
                          >　|　
                          <span class="p-ml-2"
                            ><a
                              href="javascript:void(0);"
                              (click)="
                                checkLargeCategory(
                                  'removeAll',
                                  getDicList(102),
                                  'agenda_solution_activity_area'
                                )
                              "
                              >解除</a
                            ></span
                          >
                        </div>
                        <div *ngFor="let dicData of getDicList(102)">
                          <p-checkbox
                            [label]="dicData.sp_name"
                            [value]="dicData.sp_code"
                            [formControl]="
                              c231SearchForm.controls.agenda_solution_activity_area
                            "
                            *ngIf="dicData.sp_name != '都内全域'"
                          ></p-checkbox>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="サポーターネーム">
                      <input
                        id="agenda_solution_nickname"
                        type="text"
                        pInputText
                        formControlName="agenda_solution_nickname"
                        autocomplete="off"
                      />
                    </p-accordionTab>
                    <p-accordionTab header="サポーターコード">
                      <input
                        id="user_sumasapo_no"
                        type="text"
                        pInputText
                        formControlName="user_sumasapo_no"
                        autocomplete="off"
                      />
                    </p-accordionTab>
                  </p-accordion>
                </form>
              </div>
              <div class="p-col-12 btn-block-search">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-12">
                    <button
                      pButton
                      type="button"
                      label="検索する"
                      class="p-button-danger btn-search-item"
                      (click)="getResultTaskDataList(true); changePage($event)"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-8 item-search-detail">
            <div class="p-fluid p-formgrid p-grid detail-item">
              <div class="p-col-12">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="card">
                    <p-dataView
                      #dv
                      [value]="resultTaskDataList"
                      [paginator]="true"
                      [rows]="30"
                      filterBy="name"
                      layout="grid"
                      [sortField]="sortField"
                      [sortOrder]="sortOrder"
                      (onPage)="changePage($event)"
                      emptyMessage="検索結果はありません"
                    >
                      <ng-template pTemplate="header">
                        <div
                          class="flex flex-column md:flex-row md:justify-content-between"
                        >
                          <div class="p-fluid p-formgrid p-grid p-jc-between">
                            <div class="p-col-4">
                              <span
                                class="header-title-list-item primary-title"
                              > サポーターリスト </span>
                              <a style="font-weight: normal;" class="linktext-blue underline link-pdf" (click)="openGuideDocument()">？表示アイコンについて</a>
                            </div>
                            <ng-container *ngIf="class_id === '2'">
                              <ng-container
                                *ngIf="modeMulti == true; else modeMultiple"
                              >
                                <div class="p-col-6 btn-mode-multi">
                                  <button
                                    pButton
                                    type="button"
                                    label="一括で依頼する"
                                    class="button-choose p-mr-2 border-none"
                                    (click)="confirmMulti()"
                                  ></button>
                                  <button
                                    pButton
                                    type="button"
                                    label="キャンセル"
                                    class="button-choose p-button-raised bg-white-color btn-delete"
                                    (click)="changeModeMulti(false)"
                                  ></button>
                                </div>
                              </ng-container>
                              <ng-template #modeMultiple>
                                <div class="p-col-6 btn-change-mode-multi">
                                  <button
                                    pButton
                                    type="button"
                                    label="複数選択"
                                    class="button-choose border-none"
                                    (click)="changeModeMulti(true)"
                                  ></button>
                                </div>
                              </ng-template>
                            </ng-container>

                            <div class="p-col-2 icon-view-data">
                              <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                            </div>
                          </div>
                        </div>

                        <!-- 一覧画面ヘッダー表示-->
                        <ng-container *ngIf="dv.layout == 'list'">
                          <div class="dv-header-table-header">
                            <!-- dataViewヘッダー -->
                            <p-table styleClass="p-datatable-sm generate-input">
                              <!-- 一覧画面ヘッダー表示-->
                              <ng-template pTemplate="header">
                                <tr class="p-col-2">
                                  <ng-container *ngIf="modeMulti && class_id === '2'">
                                    <th class="inputCheckbox p-pl-3">
                                      <p-checkbox
                                        name="checkAll"
                                        [(ngModel)]="allChecked"
                                        [binary]="true"
                                        (click)="setCheckAll(dv, $event)"
                                        (change)="refreshCheckItems()"
                                      ></p-checkbox>
                                    </th>
                                  </ng-container>
                                  <!-- APIヘッダーの表示 -->
                                  <ng-container
                                    *ngFor="let column of columnOrder"
                                  >
                                    <th
                                      [pSortableColumn]="column.field"
                                      (click)="onSortChange(column.field)"
                                    >
                                      {{ column.header }}
                                      <p-sortIcon
                                        [field]="column.field"
                                      ></p-sortIcon>
                                    </th>
                                  </ng-container>
                                </tr>
                              </ng-template>
                            </p-table>
                          </div>
                        </ng-container>
                      </ng-template>

                      <ng-template
                        let-searchResults
                        let-rowIndex="rowIndex"
                        pTemplate="listItem"
                      >
                        <p-table
                          #table
                          [value]="[searchResults]"
                          styleClass="p-table-striped order-table"
                          selectionMode="single"
                          dataKey="delivery_id"
                          expandableRows="true"
                        >
                          <!-- 一覧画面ボディ表示-->
                          <ng-template pTemplate="body">
                            <tr class="p-col-2">
                              <!-- チェックボックス -->
                              <ng-container *ngIf="modeMulti">
                                <td class="inputCheckbox">
                                  <p-checkbox
                                    name="cartCheckBox"
                                    value="{{
                                      searchResults.agenda_solution_agenda_id
                                    }}"
                                    [(ngModel)]="cartSelected"
                                    (click)="refreshCheckItems()"
                                  ></p-checkbox>
                                </td>
                              </ng-container>
                              <ng-container
                                *ngFor="let column of columnOrder; index as i"
                                [ngSwitch]="true"
                              >
                                <!-- 一覧項目値表示(詳細画面遷移項目) -->
                                <td *ngIf="0 == i; else hideContent">
                                  <label
                                    class="pointer"
                                    (click)="
                                      moveC331(searchResults.agenda_solution_agenda_id)
                                    "
                                  >
                                    <u>{{ searchResults[column.field] }}</u>
                                  </label>
                                </td>
                                <!-- 一覧項目値表示 -->
                                <ng-template
                                  #hideContent
                                  [ngSwitch]="column?.input_type"
                                >
                                  <td
                                    *ngSwitchCase="'NUMBER'"
                                    class="content-item"
                                    [pTooltip]="searchResults[column.field]"
                                    #tt
                                    [tooltipDisabled]="!isOverflow(tt)"
                                    showDelay="500"
                                    hideDelay="500"
                                    tooltipPosition="bottom"
                                  >
                                    {{ searchResults[column.field] | number }}
                                  </td>
                                  <td
                                    *ngSwitchDefault
                                    class="content-item"
                                    [pTooltip]="searchResults[column.field]"
                                    #tt
                                    [tooltipDisabled]="!isOverflow(tt)"
                                    showDelay="500"
                                    hideDelay="500"
                                    tooltipPosition="bottom"
                                  >
                                    {{ searchResults[column.field] }}
                                  </td>
                                </ng-template>
                              </ng-container>
                            </tr>
                          </ng-template>
                        </p-table>
                      </ng-template>
                      <ng-template let-resultTaskDataItem pTemplate="gridItem">
                        <div
                          class="p-col-6 item-detail-row p-d-flex p-ai-center"
                        >
                          <ng-container *ngIf="modeMulti && class_id == '2'">
                            <div class="p-as-center p-pr-2">
                              <p-checkbox
                                name="cartCheckBox"
                                value="{{
                                  resultTaskDataItem.agenda_solution_agenda_id
                                }}"
                                (click)="refreshCheckItems()"
                                [(ngModel)]="cartSelected"
                              ></p-checkbox>
                            </div>
                          </ng-container>
                          <div class="search-item-detail">
                            <div
                              class="search-item-description"
                              (click)="
                                moveC331(
                                  resultTaskDataItem.agenda_solution_agenda_id
                                )
                              "
                            >

                              <h6
                                class="item-description-large"
                                [pTooltip]="resultTaskDataItem.agenda_solution_nickname"
                                #tt
                                [tooltipDisabled]="!isOverflow(tt)"
                                showDelay="500"
                                hideDelay="500"
                                tooltipPosition="bottom"
                              >
                                {{ resultTaskDataItem.agenda_solution_nickname }}
                              </h6>
                              <!-- <ng-container>
                                <span class="item-area-desctiption"
                                      [pTooltip]="resultTaskDataItem.agenda_solution_activity_area"
                                      #tt
                                      [tooltipDisabled]="!isOverflow(tt)"
                                      showDelay="500"
                                      hideDelay="500"
                                      tooltipPosition="bottom">{{
                                  resultTaskDataItem?.agenda_solution_activity_area
                                }}</span>
                              </ng-container> -->
                              <ng-container
                                *ngIf="
                                  resultTaskDataItem.user_sumasapo_no
                                "
                              >
                                <span class="item-sub-description">{{
                                  resultTaskDataItem?.user_sumasapo_no
                                }}</span>
                              </ng-container>
                              <div *ngIf="resultTaskDataItem?.agenda_solution_achievement_icon">
                                <ng-container *ngFor="let iconId of resultTaskDataItem?.agenda_solution_achievement_icon.split(',')">
                                  <img class="achievement-icon" [src]="'../../../../assets/images/achievement-icon/' + iconId +'.png'" (error)="handleAchievementIconError($event)">
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-dataView>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>



<!-- メイン -->
<div class="main" *ngIf="isDataLoaded">
  <!-- メインコンテンツ -->
  <div class="main-content">
    <!-- 戻る -->
    <div class="return">
      <button
        pButton
        type="button"
        label="前のページへ戻る"
        icon="pi pi-angle-left"
        class="p-button-text"
        (click)="moveC231()"
      ></button>
      <a class="linktext-blue underline link-pdf" (click)="openGuideDocument()">？表示アイコンについて</a>
    </div>
    <div class="p-fluid p-formgrid p-grid screen-support">
      <div class="p-col-9">
        <div class="p-fluid p-formgrid p-grid support-header">
          <div class="p-col-12">
            <p-card
              class="support-header-card"
            >
              <div class="support-header-card-show">
                <div
                  class="p-fluid p-formgrid p-grid support-header-card-detail"
                >
                  <div class="p-col-3 image-container">
                    <div #avatarContainer class="support-header-card-detail-img"></div>
                  </div>
                  <div class="p-col-7 support-desc">
                    <div class="support-description-detail">
                      <span class="support-large-title primary-title">{{
                        detailTask?.nickname
                      }}</span>
                      <div *ngIf="detailTask?.achievement_icon">
                        <ng-container *ngFor="let iconId of detailTask?.achievement_icon.split(',')">
                          <img class="achievement-icon" [src]="'../../../../assets/images/achievement-icon/' + iconId +'.png'" (error)="handleAchievementIconError($event)">
                        </ng-container>
                      </div>
                      <div class="support-sub-title">
                        <span>{{ detailTask?.activity_area }}</span>
                      </div>
                      <div class="support-sub-title">
                        <span>{{ detailTask?.sumasapo_no }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="p-col-12">
            <div class="support-separate"></div>
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-first">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">略歴・抱負（自己紹介）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.resolution }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-third">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">得意領域</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.specialty }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-second">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview no-wrap"
                      >関連資格
                    </span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.qualification }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fourth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">所属（事業者・団体等）</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.company }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-sixth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">希望活動地域</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.activity_area }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">年代</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.age }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">性別</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.gender }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="p-col-12">
            <hr class="sperate-between-line" />
          </div>

          <div class="p-col-12">
            <div class="support-table-detail">
              <div class="support-line-fifth">
                <div class="p-fluid p-formgrid p-grid">
                  <div class="p-col-3 large-title">
                    <span class="title-overview">登録日</span>
                  </div>
                  <div class="p-col-9 description-title-overview">
                    <span>
                      {{ detailTask?.remark }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="p-col-12">
            <div class="support-separate"></div>
          </div>
          <div class="p-col-12">
            <ng-container *ngIf="class_id === '2'">
              <div class="p-d-flex p-jc-center">
                <div class="p-col-3 btn-review">
                  <button
                    pButton
                    type="button"
                    label="サポーターを依頼する"
                    (click)="movec401()"
                  ></button>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input (reloadID)="searchReplacement($event)"></app-generate-input>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="redirectToList($event)"></app-delete-dialog>

<!-- Toastメッセージ表示 -->
<p-toast position="top-center"></p-toast>

import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe, Location } from "@angular/common";
import {
  AGENDA_SOLUTIONS_APPROVE,
  API_URL_GET_DETAIL_AGENDA_NEEDS,
  API_URL_GET_DETAIL_AGENDA_NEEDS_NO_TOKEN,
  GENERATE_DISPLAY_NEED_MATCHING,
} from "manager/http-constants";
import { MESSAGE_CODE } from "src/app/shared/constant/message-constant";
import { TOAST } from "src/app/shared/constant/primeng-constants";
import { LoadingState } from "src/app/shared/html-parts/loading/loading-state";
import {
  MessageData,
  ToastMessageData,
} from "src/app/shared/html-parts/message-common/message-data";
import { CommonService } from "src/app/shared/service/common.service";
import { DbOperationService } from "src/app/shared/service/db-operation.service";
import { PlatformComponent } from "../platform.component";
import { ApprovalDialogComponent } from "../common/approval-dialog/approval-dialog.component";
import { LoginService } from "src/app/shared/service/login.service";
import { CONSTANT, MATCH_TYPE, RECRUITMENT_TYPE } from "src/app/shared/constant/constant";
import moment from "moment";

@Component({
  selector: "app-c3341",
  templateUrl: "./c341.component.html",
  styleUrls: ["./c341.component.scss", "../platform.component.scss"],
})

/**
 * 課題・ソリューション詳細
 */
export class C341Component extends PlatformComponent {
  /** 共通部品_承認時ダイアログ */
  @ViewChild(ApprovalDialogComponent)
  approvalDialogComponent: ApprovalDialogComponent;

  // 課題ID
  agendaId: string = this.route.snapshot.params.agenda_id;
  matchId: string = this.route.snapshot.params.match_id;

  // 課題・ソリューション詳細格納先
  detailTask: any;

  // マッチング依頼一覧格納先
  matchingList: any;

  // 質問掲示板の件数格納先
  countQuestionBoard: number = 0;
  class_id: any = this.loginUser ? this.loginUser.class_id : null;
  // variable to show content
  isDataLoaded: boolean = false;
  disabledBtnMove: boolean = false;
  disabledBtnMoveMessage: string = "";

  userInfo: any;
  constructor(
    public route: ActivatedRoute,
    public commonService: CommonService,
    public dbOperationService: DbOperationService,
    public loadingState: LoadingState,
    private router: Router,
    private messageData: MessageData,
    private location: Location,
    private datePipe: DatePipe,
    private loginService: LoginService
  ) {
    super(route, commonService, dbOperationService, loadingState);
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("showMessSessions")) {
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00003),
          detail: this.commonService.msg(MESSAGE_CODE.N90001),
          position: TOAST.BOTTOM_RIGHT,
          life: 6000,
        })
      );
    }
    sessionStorage.removeItem("showMessSessions");
    // // 課題・ソリューション詳細を取得
    this.getDetailTask();
    this.getAuthRoleUser();
  }

  /**
   * Determines whether the button for moving a task should be disabled.
   *
   * This function checks the recruitment flag and application status of the provided task detail.
   * It returns true if the task is not recruitable or if the user has already applied, 
   * or if the task is overdue based on the deadline check.
   *
   * @param {any} detailTask - The task detail object containing flags and status.
   * @returns {boolean} - Returns true if the button should be disabled, otherwise false.
   */
  disableBtnMove(detailTask:any): boolean {
    const isApplied = this.detailTask?.is_applied == "1";

    if (isApplied){
      this.disabledBtnMoveMessage = "すでに応募済みです。「応募・依頼」より活動の場主催者と連絡を取り合ってください。";
      return true;
    }

    const isOverdueDeadline = this.checkOverdueDeadline(this.detailTask);
    const isRecruitment = this.detailTask?.flag_recruitment == '1';
    if (!isApplied && (isOverdueDeadline || (!isOverdueDeadline && !isRecruitment))) {
      this.disabledBtnMoveMessage = "募集を締め切ったため、応募できません。";
      return true;
    }

    this.disabledBtnMoveMessage = CONSTANT.EMPTY_STRING;
    return false;
  }

  /**
   * Checks if the given task's deadline is overdue.
   *
   * This method evaluates whether the task has a deadline defined. If no deadline is present, it returns false. 
   * If a deadline exists, it compares the current date (in UTC+09:00) with the task's deadline to determine 
   * if the deadline has passed, returning true if it is overdue and false otherwise.
   *
   * @param {any} detailTask - The task object containing the deadline to be checked.
   * @returns {boolean} - Returns true if the deadline is overdue, false if there is no deadline or if it is not overdue.
   */
  checkOverdueDeadline(detailTask: any){
    if (!detailTask?.deadline) {
      return false;
    }

    const today = moment().utcOffset('+09:00').startOf('day');
    const deadlineDate = moment(detailTask.deadline, 'YYYY/MM/DD').startOf('day');
    return today.isAfter(deadlineDate);
  }

  public getAuthRoleUser() {
    if (!this.loginService.getAuth0LoginState()) {
      return;
    }
    return new Promise<any>((resolve) => {
      this.loginService.getLoginUser().subscribe((response) => {
        // this.loadingState.loadSleepEnd(0.3);
        this.userInfo = response.body;
        resolve(null);
      });
    });
  }

  /**
   * 課題・ソリューション詳細を取得
   */
  public getDetailTask(): void {
    if (typeof this.matchId != "undefined") {
      // 画面ロードフラグをON(ロード中状態)
      this.loadingState.loadStart(GENERATE_DISPLAY_NEED_MATCHING);
      // 課題・ソリューション詳細を取得
      this.dbOperationService
        .getNoTemplateDisplayDataMultipleArgPost(
          GENERATE_DISPLAY_NEED_MATCHING,
          this.matchId,
          this.agendaId
        )
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, GENERATE_DISPLAY_NEED_MATCHING);

          if (this.commonService.checkInvalidValue(response)) {
            this.isDataLoaded = false;
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );

            // 前画面へ戻る
            this.location.back();
          } else {
            this.isDataLoaded = true;
            // 課題・ソリューション詳細を格納
            this.detailTask = response.body;
            this.disabledBtnMove = this.disableBtnMove(this.detailTask);
          }
        });
    } else {
      let urlGetAgendaNeeds = API_URL_GET_DETAIL_AGENDA_NEEDS;
      if (!this.loginService.getAuth0LoginState()) {
        urlGetAgendaNeeds = API_URL_GET_DETAIL_AGENDA_NEEDS_NO_TOKEN;
      }
      // 画面ロードフラグをON(ロード中状態)
      this.loadingState.loadStart(urlGetAgendaNeeds);
      // 課題・ソリューション詳細を取得
      this.dbOperationService
        .getNoTemplateDisplayData(urlGetAgendaNeeds, this.agendaId)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, urlGetAgendaNeeds);

          if (this.commonService.checkInvalidValue(response)) {
            this.isDataLoaded = false;
            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E00026),
              })
            );

            // 前画面へ戻る
            this.location.back();
          } else {
            this.isDataLoaded = true;
            // 課題・ソリューション詳細を格納
            this.detailTask = response.body;
            this.disabledBtnMove = this.disableBtnMove(this.detailTask);
          }
        });
    }
  }

  /**
   * 課題・ソリューション登録(C101)へ遷移する
   */
  public moveC101(): void {
    // 課題・ソリューション登録(C101)へ遷移
    this.router.navigate(["pages/platform/c101/edit"], {
      queryParams: {
        screenId: "c101",
        agenda_id: this.route.snapshot.params.agenda_id,
        agenda_kbn: this.detailTask.agenda_kbn.id,
      },
    });
  }

  /**
   * マッチング提案掲示板(C601)へ遷移する
   * @param suggestId 提案ID
   */
  public moveC601(suggestId: string): void {
    // マッチング提案掲示板(C601)へ遷移
    this.router.navigate(["pages/platform/c601/" + suggestId], {
      queryParams: {
        agenda_id: this.route.snapshot.params.agenda_id,
      },
    });
  }

  /**
   * 内閣府承認ダイアログ表示
   */
  public openSmallApprovalDialog(): void {
    // 内閣府承認ダイアログ表示
    this.approvalDialogComponent.openSmallApprovalDialog(
      AGENDA_SOLUTIONS_APPROVE,
      this.agendaId,
      2003,
      0,
      1
    );
  }

  moveC241() {
    history.back();
  }
  public moveC441(): void {
    if (this.disabledBtnMove) {
      return;
    }

    this.router.navigate(["pages/platform/c401/new"], {
      queryParams: {
        screenId: "c401",
        agenda_id: this.route.snapshot.params.agenda_id,
        match_type: MATCH_TYPE.AGENDA_NEED,
      },
    });
  }

  public setClassesKind(recruitment_kind: any) {
    if (recruitment_kind === RECRUITMENT_TYPE.CONFERENCE) {
      return "color-conference";
    } else if (recruitment_kind === RECRUITMENT_TYPE.OTHERS) {
      return "color-others";
    } else if (recruitment_kind === RECRUITMENT_TYPE.TYPE_3) {
      return "color-recruitment-kind3";
    } else {
      return "no-category";
    }
  }
}
